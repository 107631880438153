import { ICardAA, ICardCategoryAA } from "@origin-digital/reporting-client";
import {
  isContentCardSignPost,
  isContentCard,
  isContentCardCrossSell,
  ContentCardType,
} from "@origin-digital/event-dispatcher";

export const getTrackingData = (card: ContentCardType): ICardAA => {
  let primaryCta = "";
  if (isContentCardSignPost(card) || isContentCard(card)) {
    primaryCta = card.ctaContained?.label ?? "Missing label";
  }
  if (isContentCardCrossSell(card)) {
    primaryCta = card.ctaLink?.label ?? "Missing label";
  }

  const data = {
    category: "dashboard-card" as ICardCategoryAA,
    id: card.experienceId ?? "experienceId not found",
    title: card.title ?? "",
    primaryCta,
  };
  return data;
};
