import { useEffect, useState } from "react";
import {
  contentCards,
  IContentCardsResult,
} from "@origin-digital/event-dispatcher";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { DashboardCard } from "../types";
import { contentCardLocations } from "src/consts";
import { getTrackingData } from "../analytics";

const DEFAULT_CARDS_LIMIT = 1;
const MAX_CROSS_SELL_CARDS_LIMIT = 10;

const isCrossSellLocation = (location: contentCardLocations) =>
  location === contentCardLocations.CROSS_SELL;

export const useCardContent = (location: contentCardLocations) => {
  const [cardContent, setCardContent] = useState<DashboardCard[]>();
  const { updateWidgetStatus } = useWidgetProvider();
  const limit = isCrossSellLocation(location)
    ? MAX_CROSS_SELL_CARDS_LIMIT
    : DEFAULT_CARDS_LIMIT;

  useEffect(() => {
    contentCards({ location, limit }).then(({ cards }: IContentCardsResult) => {
      if (cards.length) {
        const locationFilteredCards = isCrossSellLocation(location)
          ? cards
          : [cards[0]];

        const dataArr = locationFilteredCards.map((card) =>
          getTrackingData(card)
        );
        const dashboardCards = locationFilteredCards.map((card) => ({
          ...card,
          appContext: "dashboard",
        }));
        setCardContent(dashboardCards as DashboardCard[]);
        updateWidgetStatus(WidgetStatusCode.COMPLETE, dataArr);
      }
    });
  }, [location, updateWidgetStatus]);

  return cardContent;
};

export default useCardContent;
