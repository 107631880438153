import React from "react";

import { Stack } from "@origin-digital/ods-core";

import { BrazeCardWidgetProps } from "./types";
import { useCardContent } from "./hooks/useCardContent";
import { contentCardLocations } from "src/consts";
import { CardView } from "./components/CardView";

if (__DEV__) {
  require("./mocks/eventDispatcherMocks.ts");
}

export const BrazeCardWidget: React.FC<BrazeCardWidgetProps> = ({
  location,
}) => {
  const cardContent = useCardContent(location as contentCardLocations);

  if (!cardContent) {
    return null;
  }

  return cardContent.length > 1 ? (
    <Stack>
      {cardContent.map((card) => (
        <CardView {...card} key={card.experienceId} />
      ))}
    </Stack>
  ) : (
    <CardView {...cardContent[0]} />
  );
};
