import { WidgetDefinition } from "../../WidgetDefinition";
import { BrazeCardWidget } from "./src/";
import { BrazeCardWidgetProps } from "./src/types";

export const brazeCardWidgetDefinition = (
  location: string
): WidgetDefinition<BrazeCardWidgetProps> => ({
  widgetComponent: BrazeCardWidget,
  name: "BrazeCardWidget",
  propsBuilder: () => ({
    location,
  }),
});
