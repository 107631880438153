import React from "react";
import {
  ContentCardWidget,
  CrossSellCardWidget,
  SignPostCardWidget,
} from "@origin-digital/personalisation-widget";
import { TrackingProvider } from "@origin-digital/ods-core";
import { trackingProviderCaptureClickCard } from "@origin-digital/widget-helpers";

import { DashboardCard } from "../types";
import { getTrackingData } from "../analytics";

export const CardView = (card: DashboardCard) => {
  const trackingData = getTrackingData(card);
  let CardComponent: React.ElementType = ContentCardWidget;
  if (card.type === "signPostCard") {
    CardComponent = SignPostCardWidget;
  } else if (card.type === "crossSellCard") {
    CardComponent = CrossSellCardWidget;
  }

  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(trackingData)}
    >
      <CardComponent {...card} />
    </TrackingProvider>
  );
};
